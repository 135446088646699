import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { OneTapLoginConstants, checkCartAndLoginCookie } from "./common";

/**
 * This functional component provides the functionality of Google One Tap Login
 *
 * @returns {React.ReactElement} It returns nothing.
 */
const OneTapLogin = () => {
  const router = useRouter();

  /**
   * @function handleCredentialResponse this is the callback function from the initialisation
   * @param {*} res credential response in form of JWT
   */
  const handleCredentialResponse = (res) => {
    if (res.credential) {
      router.push({
        pathname: `${window.location.origin}${OneTapLoginConstants.REDIRECT_PATH}`,
        query: {
          DONE_PAGE: window.location.href,
          jwtToken: res.credential,
        },
      });
    }
  };

  useEffect(() => {
    if (!checkCartAndLoginCookie()) {
      // Initialize Google One Tap
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: OneTapLoginConstants.CLIENT_ID,
          callback: handleCredentialResponse,
          cancel_on_tap_outside: false,
        });
        window.google.accounts.id.prompt();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <></>;
};

export default OneTapLogin;
